/* Reduce vertical padding in list rows */
.fc-list-event-time,
.fc-list-event-title {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-right: 4px !important;
    padding-left: 8px !important;
}

.fc-list-event-graphic {
    display: none;
}

.fc-list-table {
    min-width: 800px;
}

.fc-scroller {
    overflow: scroll !important;
}

@media screen and (max-width: 700px) {
    .fc .fc-toolbar-title {
        font-size: 1em;
    }

    .btn {
        font-size: 0.85rem;
        padding: 2px 2px;
    }

    .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
        margin-left: 0.25em;
    }
}

@media print {
    * {
        overflow: visible !important;
    }

    .fc-list-event-time,
    .fc-list-event-graphic,
    .fc-list-event-title {
        padding: 2px 2px !important;
        font-size: 12px;
    }
}

.opacity-40 {
    opacity: 0.4;
}

.shift-border {
    border-top: 5px solid #3771F6;
}